import * as React from "react";
import sidevideo from "./assets/sid-2022.mp4";
import "./assets/styles.css";
import NavBar from "./navbar";

const footerStyle = {
  textAlign: "center",
  color: "#716969",
  fontSize: 10,
};

// const textStyle = {
//   textAlign : "center",
//   color: "#f0f0f0",
//   fontSize: 24
// }

const pageStyles = {
  color: "#232129",
  backgroundColor: "#ffffff",
  padding: 0,
  height: "100%",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  texColor: "#ffffff",
};

const vidStyle = {
  maxWidth: "50%",
  height: "auto",
};

const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Cogni-Trax</title>
      <NavBar />

      <video style={vidStyle} controls autoPlay loop>
        <source src={sidevideo} type="video/mp4" />
      </video>

      <div id="footer" style={footerStyle}>
        <p className="copyright">&copy; Cogni-Trax </p>
      </div>
    </main>
  );
};

export default IndexPage;
